.ui-post {
    width: 400px;

    &__title {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
    }

    &__body {
        padding: 20px;
        border-radius: 5px;
        background-color: #673AB7;
        color: #fff;
        text-align: left;

        &__title {
            font-size: 14px;
            margin: 0;
            line-height: 1.5;
            font-weight: bold;
        }

        &__description {
            margin: 10px 0 0 0;
            font-size: 12px;
            line-height: 1.5;
            margin-bottom: 10px;
            font-weight: 300;
        }
    }
}

.master-progress {
    margin-bottom: 30px;
    overflow: hidden;
    color: lightgrey;
    text-align: center;
    display: flex;
    flex-direction: row;
}

.master-progress .active {
    color: #000000;
}

.master-progress li {
    list-style-type: none;
    font-size: 12px;
    position: relative;
    flex: 1;
}

.master-progress i {
    width: 50px;
    height: 50px;
    line-height: 45px;
    display: block;
    font-size: 18px;
    color: #ffffff;
    background: lightgray;
    border-radius: 50%;
    margin: 0 auto 10px auto;
    padding: 2px;
}

.master-progress li:after {
    content: '';
    width: 100%;
    height: 2px;
    background: lightgray;
    position: absolute;
    left: 0;
    top: 25px;
    z-index: -1;
}
.master-progress li.active i, .master-progress li.active:after {
    background: var(--basic-orange);
}
.master-progress li.selected i, .master-progress li.selected:after {
    background: var(--basic-green);
}