/*** fonts ***/

@font-face {
	font-family: 'graphik';
	src: local('graphik'), local('graphik'), url('../../assets/fonts/graphik/graphik-100.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'graphik';
	src: local('graphik'), local('graphik'), url('../../assets/fonts/graphik/graphik-300.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'graphik';
	src: local('graphik'), local('graphik'), url('../../assets/fonts/graphik/graphik-500.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'graphik';
	src: local('graphik'), local('graphik'), url('../../assets/fonts/graphik/graphik-600.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'graphik';
	src: local('graphik'), local('graphik'), url('../../assets/fonts/graphik/graphik-700.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'graphik';
	src: local('graphik'), local('graphik'), url('../../assets/fonts/graphik/graphik-900.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
    font-family: 'euclid-square';
    src: url('../../assets/fonts/euclid-square/euclid-square-300/euclid-square-300.eot');
    src: url('../../assets/fonts/euclid-square/euclid-square-300/euclid-square-300.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/euclid-square/euclid-square-300/euclid-square-300.woff2') format('woff2'),
         url('../../assets/fonts/euclid-square/euclid-square-300/euclid-square-300.woff') format('woff'),
         url('../../assets/fonts/euclid-square/euclid-square-300/euclid-square-300.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'euclid-square';
    src: url('../../assets/fonts/euclid-square/euclid-square-400/euclid-square-400.eot');
    src: url('../../assets/fonts/euclid-square/euclid-square-400/euclid-square-400.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/euclid-square/euclid-square-400/euclid-square-400.woff2') format('woff2'),
         url('../../assets/fonts/euclid-square/euclid-square-400/euclid-square-400.woff') format('woff'),
         url('../../assets/fonts/euclid-square/euclid-square-400/euclid-square-400.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'euclid-square';
    src: url('../../assets/fonts/euclid-square/euclid-square-500/euclid-square-500.eot');
    src: url('../../assets/fonts/euclid-square/euclid-square-500/euclid-square-500.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/euclid-square/euclid-square-500/euclid-square-500.woff2') format('woff2'),
         url('../../assets/fonts/euclid-square/euclid-square-500/euclid-square-500.woff') format('woff'),
         url('../../assets/fonts/euclid-square/euclid-square-500/euclid-square-500.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'euclid-square';
    src: url('../../assets/fonts/euclid-square/euclid-square-600/euclid-square-600.eot');
    src: url('../../assets/fonts/euclid-square/euclid-square-600/euclid-square-600.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/euclid-square/euclid-square-600/euclid-square-600.woff2') format('woff2'),
         url('../../assets/fonts/euclid-square/euclid-square-600/euclid-square-600.woff') format('woff'),
         url('../../assets/fonts/euclid-square/euclid-square-600/euclid-square-600.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'euclid-square';
    src: url('../../assets/fonts/euclid-square/euclid-square-700/euclid-square-700.eot');
    src: url('../../assets/fonts/euclid-square/euclid-square-700/euclid-square-700.eot?#iefix') format('embedded-opentype'),
         url('../../assets/fonts/euclid-square/euclid-square-700/euclid-square-700.woff2') format('woff2'),
         url('../../assets/fonts/euclid-square/euclid-square-700/euclid-square-700.woff') format('woff'),
         url('../../assets/fonts/euclid-square/euclid-square-700/euclid-square-700.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
	font-family: 'icons';
	src: local('icons'), local('icons'), url('../../assets/fonts/icons.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

/*** end.fonts ***/