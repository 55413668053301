@media screen and (max-width: 620px) {



    html,
    input,
    textarea,
    p {
        font-weight: 300;
        font-size: 18px;
        line-height: 24px;
    }



    /*** ------------ headings, text ------------ ***/

    h1,
    h2,
    h3,
    h4,
    h5 {
        margin: 0 0 16px;
    }

    * + h1,
    * + h2,
    * + h3,
    * + h4,
    * + h5 {
        margin-top: 24px;
    }

    h1 {
        font-size: 36px;
        line-height: 40px;
    }
    h2 {
        font-size: 28px;
        line-height: 32px;
    }
    h3 {
        font-size: 24px;
        line-height: 28px;
    }
    h4 {
        font-size: 20px;
        line-height: 24px;
    }
    h4 {
        font-size: 16px;
        line-height: 20px;
    }

    /*** ------------ end.headings, text ------------ ***/

    /*** ------------ links ------------ ***/

    a {
        text-decoration: none;
    }

    /*** ------------ link__button ------------ ***/

    .link__button {
        display: inline-block;
        padding: 8px 16px;
        border-radius: 16px;
    }

    /*** ------------ link__big-button ------------ ***/

    .link__big-button {
        display: inline-block;
        padding: 16px 32px;
        border-radius: 16px;
        border: 1.5px solid var(--basic-grey-border);
    }

    .link__big-button:hover {
        color: var(--basic-white);
        background: var(--basic-green);
        border-color: var(--basic-green);
    }

    /*** ------------ end.links ------------ ***/

    /*** ------------ wrap ------------ ***/

    .container {
        padding: 0 8px;
    }

    /*** ------------ end.wrap ------------ ***/

    /*** ------------ absolute-* ------------ ***/

    .absolute-bottom {
        bottom: 24px;
    }

    /*** ------------ end.absolute-* ------------ ***/

    /*** ------------ header ------------ ***/

    header {
        padding: 16px 0;
    }

    header .wrap.d-flex {
        align-items: center;
    }

    header.header__scroll {
        padding: 8px 0;
    }

    .header-link__enter {
        display: none;
        font-size: 13px;
        margin-left: auto;
        background: var(--basic-grey-pale);
    }

    /*** ------------ end.header ------------ ***/

    /*** ------------ logo ------------ ***/

    .logo {
        margin-right: 16px;
        font: 600 20px/16px 'euclid-square';
    }

    .logo span {
        display: block;
    }

    /*** ------------ end.logo ------------ ***/

    /*** ------------ city ------------ ***/

    .city__change-button {
        display: none;
    }

    /*** ------------ end.city ------------ ***/

    /*** ------------ main__menu ------------ ***/

    .main__menu > li + li {
        margin-left: 24px;
    }

    .main__menu > li > a {
        font-size: 16px;
        line-height: 24px;
    }

    /*** ------------ header__menu ------------ ***/

    header .main__menu li:nth-child(1) {
        display: none;
    }

    header .main__menu > li > a {
        font-size: 14px;
    }

    /*** ------------ end.main__menu ------------ ***/

    /*** ------------ item-grid ------------ ***/

    /*.item-grid {
        width: 100%;
        margin-bottom: -16px;
    }

    .item-grid:first-child {
        margin-top: -16px;
    }

    .item-grid .item {
        padding: 16px 0;
    }*/

    .item-grid.item-grid-2 .item,
    .item-grid.item-grid-3 .item,
    .item-grid.item-grid-4 .item,
    .item-grid.item-grid-5 .item,
    .item-grid.item-grid-6 .item {
        width: 100%;
    }

    /*** ------------ end.item-grid ------------ ***/

    /*** ------------ content ------------ ***/

    #content_wrap {
        overflow-x: hidden;
    }

    .header__fixed + #content_wrap {
        padding-top: 88px;
    }

    .header__fixed.header__with-sub-menu + #content_wrap {
        padding-top: 120px;
    }

    .enter__page .header__fixed + #content_wrap {
        padding-top: 0;
    }

    .inner__page header + #content_wrap:before {
        content: '';
        position: absolute;
        top: 88px;
        left: 0;
        display: block;
        width: 100%;
        height: 1.5px;
        background: var(--basic-grey-light);
        z-index: 12;
    }

    .content {
        padding-top: 48px;
        padding-bottom: 48px;
    }

    /*** ------------ end.content ------------ ***/

    /*** ------------ hello__block ------------ ***/

    .hello__block {
        padding: 48px 0 96px;
    }

    .hello-block__title {
        font-weight: 400;
        font-size: 32px;
        line-height: 36px;
    }

    /*** ------------ end.hello__block ------------ ***/

    /*** ------------ search__block ------------ ***/

    .search__block form {
        padding: 8px;
    }

    .search-block__input {
        width: 70%;
        padding-right: 16px;
    }

    .search-block__button {
        width: 30%;
    }

    .search-block__input input,
    .search-block__button button {
        font-weight: 100;
        font-size: 16px;
        line-height: 24px;
    }

    .search-block__input input {
        padding-right: 0;
    }

    * + .search-block__tags {
        margin-top: 16px;
    }

    * + .search-block-tags__list {
        margin-left: 16px;
    }

    .search-block-tags__list li {
        padding: 8px 16px;
    }

    .search-block-tags__list li:nth-child(n+2) {
        display: none;
    }

    /*** ------------ end.search__block ------------ ***/

    /*** ------------ service__list ------------ ***/

    * + .service-list__wrap {
        margin-top: 32px;
    }

    .service__list:before {
        width: 100%;
        border-radius: 0;
    }

    .service-list-wrap__item {
        height: 186px;
    }

    .service-list-wrap__about {
        padding: 8px 4px;
        bottom: 4px;
        width: calc(100% - 8px);
    }

    /*** ------------ end.service__list ------------ ***/

    /*** ------------ categories__tile ------------ ***/

    .categories__tile .wrap {
        padding: 16px;
    }

    .categories-tile__title {
        font-size: 14px;
    }

    /*** ------------ end.categories__tile ------------ ***/

    /*** ------------ result__list ------------ ***/

    .result-list__wrap li {
        padding: 16px;
    }

    .result-company__logo {
        width: 64px;
        height: 64px;
        border-radius: 16px;
    }

    * + .result-company__about {
        width: calc(100% - 80px);
        margin-left: 16px;
    }

    .result-company__title {
        font: 400 20px/24px 'euclid-square';
    }

    .result-list__map {
        height: 400px;
    }

    /*** ------------ end.result__list ------------ ***/

    /*** ------------ how-it-works__block ------------ ***/

    .how-it-works__block .item > .wrap {
        height: 296px;
    }

    .how-it-works__block .item > .wrap:before {
        font: 700 64px/64px 'euclid-square';
    }

    /*** ------------ end.how-it-works__block ------------ ***/

    /*** ------------ blog-news__block ------------ ***/

    .blog-news__block .item a {
        min-height: 256px;
    }

    /*** ------------ end.blog-news__block ------------ ***/

    /*** ------------ footer ------------ ***/

    footer {
    }

    /*** ------------ footer__menu ------------ ***/

    .footer__menu {
        margin-top: 16px;
    }

    footer > .wrap:last-child .footer__menu > li + li {
        margin-top: 8px;
    }

    .footer__menu > li + li {
        margin-left: 0;
    }

    /*** ------------ end.footer ------------ ***/

    /*** ------------ why-we__block ------------ ***/

    * + .why-we__block {
        margin-top:24px;
    }

    .why-we__block + .why-we__block {
        margin-top:32px;
    }

    .why-we__block .item__img {
        order:-1;
    }

    .why-we__block .item__img .wrap {
        width:100%;
        height:196px;
    }

    .why-we__block .item__img:nth-child(1) .wrap,
    .why-we__block .item__img:nth-child(2) .wrap {
        margin:0;
    }

    /*** ------------ why-we__block ------------ ***/

    .mapSwitcher {
        display: flex;
        align-content: flex-end;
        flex-direction: row-reverse;
    }

    .showMap, .showList {
        display: block;

        &>span {
            border-radius: 8px;
            padding: 8px;
            border: 1px solid var(--basic-grey-light);
        }
    }

    .hideMap, .hideList {
        display: none;
    }
}