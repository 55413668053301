.ui-counter {
    width: 400px;

    &__title {
        font-size: 18px;
        text-transform: uppercase;
        font-weight: bold;
    }

    &__body {
        padding: 20px;
        border-radius: 5px;
        background-color: #673AB7;
        color: #fff;
        text-align: center;
        
        &__name {
            font-size: 24px;
            margin: 0;
            line-height: 1.5;
            font-weight: bold;
        }
    
        &__count {
            margin: 0;
            font-size: 50px;
            font-weight: bold;
            line-height: 1.5;
            margin-bottom: 10px;
            font-weight: 300;
        }

        &__button {
            height: 36px;
            background-color: #fff;
            border-radius: 3px;
            border: none;
            width: 200px;
            font-weight: bold;
            text-transform: uppercase;
            outline: none;
            cursor: pointer;
        }
    }
}