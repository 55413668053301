@import "fonts";
@import "normalize";

*,
*:after,
*:before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

:root {

  --basic-white: #fff;
  --basic-black: #444343;
  --basic-dark: #3d3c3c;

  --basic-grey: #8d8d8d;
  --basic-grey-light: #ececec;
  --basic-grey-pale: #f1f1f1;
  --basic-grey-border: #d4d4d4;
  --basic-grey-footer: #8a8a8a;

  --basic-orange: #ed845d;
  --basic-mint: #aacdc5;

  --basic-green: #5cb85c;
  --basic-red: #d9534f;
}

html,
input,
textarea {
}

html,
input,
textarea,
p {
  position: relative;
  font-family: 'graphik', Tahoma, Calibri;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: var(--basic-black);
}

body {
  position: relative;
  text-align: left;
  margin: 0 auto;
  background: var(--basic-white);
  -webkit-font-smoothing: antialiased;
  width: calc(1024px + 56px);
  max-width: 100%;
  overflow-x: hidden;
}

body:before {
  display: block;
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--basic-black);
  opacity: 0;
  transition: all .4s ease;
}

body.show-menu:before {
  opacity: .7;
  transition: all .4s ease;
  z-index: 998;
}

::selection {
  background-color: var(--basic-grey-light);
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-track {
  background: var(--basic-white);
  border-radius: 8px;
}

::-webkit-scrollbar-thumb {
  background: var(--basic-black);
  border-radius: 8px;
}

a,
input,
select,
textarea {
  outline: none;
}

img {
  vertical-align: top;
}

iframe {
  border: none;
  vertical-align: top;
}

ul,
ol {
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
}

/*** ------------ clear ------------ ***/

header:after,
.wrap:not(.d-flex):after,
.container:not(.d-flex):after,
ul:after,
ol:after,
form:after {
  content: " ";
  display: block;
  clear: both;
  overflow: hidden;
}

*:first-child {
  margin-top: 0;
}

*:last-child {
  margin-bottom: 0;
}

/*** ------------ end.clear ------------ ***/

/*** ------------ headings, text ------------ ***/

h1,
h2,
h3,
h4,
h5 {
  position: relative;
  font-family: 'euclid-square';
  font-weight: 500;
  margin: 0 0 24px;
}

* + h1,
* + h2,
* + h3,
* + h4,
* + h5 {
  margin-top: 32px;
}

h1 {
  font-size: 48px;
  line-height: 56px;
}

h2 {
  font-size: 40px;
  line-height: 48px;
}

h3 {
  font-size: 32px;
  line-height: 40px;
}

h4 {
  font-size: 24px;
  line-height: 32px;
}

h5 {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 500;
}

p {
  margin: 0;
}

p + p,
* + ul.ul__list,
ul.ul__list + p,
ul.ul__list li + li {
  margin-top: 12px;
}

.text__big {
  font-weight: 300;
  font-size: 24px;
  line-height: 32px;
}

.text__small {
  font-size: 14px;
  line-height: 22px;
}

.text__extra-small {
  font-size: 12px;
  line-height: 18px;
}

.text__extra_extra-small {
  font-size: 10px;
  line-height: 14px;
  color: #999999;
}

.text__uppercase {
  text-transform: uppercase;
}

.text__center {
  text-align: center;
}

.text__color-white {
  color: var(--basic-white);
}

.text__color-grey {
  color: var(--basic-grey);
}

.text__color-green {
  color: var(--basic-green);
}

.text__color-orange {
  color: var(--basic-orange);
}

b,
strong {
  font-weight: 500;
}

i {
  font-style: italic;
}

ul,
ol {
  position: relative;
  padding: 0;
  margin: 0;
  list-style: none;
}

.opacity {
  opacity: .64;
}

ul.ul__list li {
  position: relative;
  padding-left: 32px;
}

ul.ul__list li:before {
  content: '';
  position: absolute;
  top: 12px;
  left: 0;
  display: block;
  width: 16px;
  height: 1.5px;
  background: var(--basic-black);
  z-index: 12;
}

/*** ------------ end.headings, text ------------ ***/

/*** icon__before ***/

.icon__before {
  position: relative;
  padding-left: 24px;
}

.icon__before:before {
  position: absolute;
  top: 0;
  left: 0;
  font: 500 12px/22px "icons";
}

span.icon__before:before {
  line-height: 13px;
}

.icon__person:before {
  content: "\e005";
}

.icon__adress:before {
  content: "\e096";
}

.icon__phone:before {
  content: "\e010";
}

.icon__mail:before {
  content: "\e01e";
}

.icon__more:before {
  content: "\e051";
}

.icon__clock:before {
  content: "\e081";
}

.icon__video:before {
  content: "\e008";
}

.icon__link:before {
  content: "\e051";
}

.icon-pencil:before {
  content: "\e05f";
}

.icon-magnifier:before {
  content: "\e090";
}

.icon-diamond:before {
  content: "\e043";
}

.icon-briefcase:before {
  content: "\e04b";
}

.icon-close:before {
  content: "\e082";
}

/*** end.icon__before ***/

/*** ------------ links ------------ ***/

a {
  position: relative;
  color: var(--basic-black);
  text-decoration: none;
  transition: all .4s ease;
}

a:hover {
  color: var(--basic-black);
  transition: all .4s ease;
}

/*** ------------ link__button ------------ ***/

.link__button {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 16px;
}

/*** ------------ link__big-button ------------ ***/

.link__big-button {
  display: inline-block;
  padding: 16px 32px;
  border-radius: 16px;
  border: 1.5px solid var(--basic-grey-border);
}

.link__big-button:hover {
  color: var(--basic-white);
  background: var(--basic-green);
  border-color: var(--basic-green);
}

/*** ------------ end.links ------------ ***/

/*** ------------ form ------------ ***/

form,
button,
submit,
select {
}

input[type="text"],
button[type="submit"],
input[type="radio"],
input[type="checkbox"],
select,
textarea {
  position: relative;
  display: inline-block;
  width: 100%;
  font-family: 'euclid-square';
  font-size: 14px;
  line-height: 16px;
  padding: 16px;
  border-radius: 16px;
  border: 0;
}

input[type="text"] {
  background: transparent;
}

button[type="submit"] {
  color: var(--basic-white);
  background: var(--basic-green);
  transition: all .4s ease;
}

button[type="submit"]:hover {
  color: var(--basic-white);
  background: var(--basic-black);
  transition: all .4s ease;
}

select {
  font-weight: 300;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
  text-align: left;
}

select optgroup {
  font-size: 16px !important;
  line-height: 24px !important;
}

select option {
  font-size: 14px !important;
  line-height: 22px !important;
}

input[type="radio"] {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: var(--basic-grey);
}

input[type="radio"]:checked {
  background: var(--basic-orange);
}

input[type="checkbox"] {
  width: 16px;
  height: 16px;
  background: var(--basic-grey);
}

input[type="checkbox"]:checked {
  background: var(--basic-orange);
}

textarea {
  min-width: 100%;
  max-width: 100%;
  height: 136px;
  min-height: 136px;
  max-height: 184px;
}

::-webkit-input-placeholder {
  color: var(--basic-black);
}

::-moz-placeholder {
  color: var(--basic-black);
}

:-moz-placeholder {
  color: var(--basic-black);
}

:-ms-input-placeholder {
  color: var(--basic-black);
}

/*** ------------ end.form ------------ ***/

/*** ------------ field__wrapper ------------ ***/

.field__wrapper {
  width: 100%;
  position: relative;
  text-align: center;
  border-radius: 16px;
  overflow: hidden;
}

.field__file {
  opacity: 0;
  visibility: hidden;
  position: absolute;
}

.field__file-wrapper {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.field__file-fake,
.field__file-button {
  height: 48px;
  font-size: 14px;
  line-height: 14px;
  padding: 0 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.field__file-fake {
  width: calc(100% - 128px);
  background: var(--basic-white);
  font-weight: 300;
}

.field__file-button {
  width: 128px;
  color: var(--basic-white);
  background: var(--basic-black);
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
}

.create-order-block__main h4 {
  font-weight: 300;
}

.create-order-block__info {
  padding: 32px;
  background: var(--basic-white);
  border-radius: 16px;
}

* + .create-order-block__info {
  margin-top: 24px;
}

/*** ------------ end.field__wrapper ------------ ***/

/*** ------------ wrap ------------ ***/

#wrapper {
  position: relative;
  display: block;
  width: 100%;
}

.wrap {
  position: relative;
  display: block;
  width: 100%;
}

.container {
  position: relative;
  display: block;
  width: 1024px;
  max-width: 100%;
  padding: 0 16px;
  margin: auto;
}

/*** ------------ end.wrap ------------ ***/

/*** ------------ flex ------------ ***/

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.fd-column {
  flex-direction: column;
}

.fd-row-reverse {
  flex-direction: row-reverse;
}

.fd-column-reverse {
  flex-direction: column-reverse;
}

.jc-space-between {
  justify-content: space-between;
}

.ai-center {
  align-items: center;
}

.ai-baseline {
  align-items: baseline;
}

/*** ------------ end.flex ------------ ***/

/*** ------------ absolute-* ------------ ***/

.absolute-center,
.absolute-bottom,
.popup {
  position: absolute;
  left: 50%;
  z-index: 23;
}

.absolute-center,
.popup {
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

.absolute-bottom {
  bottom: 32px;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
}

@media screen and (max-width: 620px) {

  .absolute-bottom {
    bottom: 24px;
  }

}

/*** ------------ end.absolute-* ------------ ***/

/*** ------------ header ------------ ***/

header {
  position: relative;
  display: block;
  width: 100%;
  padding: 24px 0;
  background: var(--basic-white);
  z-index: 456;
  transition: all .4s ease;
}

.enter__page header {
  background: transparent;
}

header.header__fixed {
  position: fixed;
  top: 0;
  left: 0;
}

header.header__scroll {
  padding: 16px 0;
  background: linear-gradient(0, rgba(255, 255, 255, 0) 0, var(--basic-white) 50%);
  transition: all .4s ease;
}

.header-link__enter {
  margin-left: 64px;
  background: var(--basic-grey-pale);
}

.header-link__enter:hover {
  color: var(--basic-white);
  background: var(--basic-black);
}

/*** ------------ end.header ------------ ***/

/*** ------------ logo ------------ ***/

.logo {
  position: relative;
  display: inline-block;
  margin-right: 32px;
  font: 600 32px/40px 'euclid-square';
  text-transform: uppercase;
  color: var(--basic-orange);
  border: 0;
  z-index: 12;
}

header .logo + * {
  margin-left: 0;
}

header .logo:hover,
footer .logo:hover {
  color: var(--basic-orange);
}

footer .logo {
  color: var(--basic-white);
}

/*** ------------ end.logo ------------ ***/

/*** ------------ city ------------ ***/

.city__change-button {
  position: relative;
  display: inline-block;
  margin: 8px 32px;
  font-size: 14px;
  line-height: 22px;
  color: var(--basic-grey);
  border-bottom: 1.5px solid var(--basic-grey-border);
}

.city__change-button:hover {
  color: var(--basic-black);
  border-color: var(--basic-black);
}

/*** ------------ end.city ------------ ***/

/*** ------------ main__menu ------------ ***/

.main__menu {
  position: relative;
}

.main__menu > li {
  position: relative;
  display: block;
}

.main__menu > li + li {
  margin-left: 32px;
}

.main__menu > li > a {
  font-size: 16px;
  line-height: 24px;
  /*text-transform:uppercase;*/
  border: 0;
}

.main__menu > li > a:hover {
  color: var(--basic-green);
}

/*** ------------ header__menu ------------ ***/

header .main__menu {
  margin-left: auto;
}

header .main__menu + .main__menu {
  margin-left: 32px;
}

header .main__menu > li > a {
  line-height: 40px;
  color: var(--basic-black);
}

header .main__menu > li > ul {
  position: absolute;
  top: calc(100% + 16px);
  left: -32px;
  display: block;
  padding: 8px 0;
  background: var(--basic-grey-pale);
  border-radius: 16px;
  opacity: 0;
  pointer-events: none;
  white-space: nowrap;
  overflow: hidden;
  transition: all .4s ease;
}

header .main__menu > li:hover > ul {
  top: 100%;
  opacity: 1;
  pointer-events: auto;
  transition: all .4s ease;
}

header .main__menu > li > ul > li > a {
  display: block;
  padding: 8px 32px;
  font-size: 14px;
  line-height: 24px;
}

header .main__menu > li > ul > li > a:hover {
  color: var(--basic-white);
  background: var(--basic-green);
}

header .main__menu > li > ul > li.active a,
header .main__menu > li > ul > li a.active {
  color: var(--basic-white);
  background: var(--basic-black);
}

/*** ------------ footer__menu ------------ ***/

footer .main__menu {
  position: relative;
}

/*** ------------ end.main__menu ------------ ***/

/*** ------------ item-grid ------------ ***/

.item-grid {
  width: 100%;
  //width: calc(100% + 32px);
  //margin: -16px;
}

.item-grid .item {
  //padding: 16px;
}

.item-grid a.item {
  border: 0;
}

.item-grid.item-grid-2 .item {
  width: 50%;
}

.item-grid.item-grid-3 .item {
  width: 33.333%;
}

.item-grid.item-grid-4 .item {
  width: 25%;
}

.item-grid.item-grid-5 .item {
  width: 20%;
}

.item-grid.item-grid-6 .item {
  width: 16.666%;
}

.item-grid.item-grid-3to2 .item {
  width: 32%;
  margin-bottom: 2%;

  @media all and (max-width: 767px) {
    width: 49%;
    margin-bottom: 2%;
  }
}

@media screen and (max-width: 620px) {

  .item-grid {
    width: 100%;
  }

  .item-grid .item {
    //padding: 16px 0;
  }

  .item-grid.item-grid-2 .item,
  .item-grid.item-grid-3 .item,
  .item-grid.item-grid-4 .item,
  .item-grid.item-grid-5 .item,
  .item-grid.item-grid-6 .item {
    width: 100%;
  }

}

/*** ------------ end.item-grid ------------ ***/

/*** ------------ shadow-effect ------------ ***/

.shadow-effect:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  opacity: .48;
  z-index: 1;
  transition: all .4s ease;
}

.shadow-effect.shadow-effect__white:before {
  background: var(--basic-white);
}

.shadow-effect.shadow-effect__black:before {
  background: var(--basic-black);
}

.shadow-effect.shadow-effect__blue:before {
  background: var(--basic-black-royal);
}

/*** ------------ end.shadow-effect ------------ ***/

/*** ------------ content ------------ ***/

#content_wrap {
  z-index: 100;
}

.header__fixed + #content_wrap {
  padding-top: 88px;
}

.header__fixed.header__with-sub-menu + #content_wrap {
  padding-top: 120px;
}

.enter__page .header__fixed + #content_wrap {
  padding-top: 0;
}

.authorized__page header + #content_wrap:before {
  content: '';
  position: absolute;
  top: 88px;
  left: 0;
  display: block;
  width: 100%;
  height: 1.5px;
  background: var(--basic-grey-light);
  z-index: 12;
}

.content {
  padding-top: 48px;
  padding-bottom: 48px;
}

.border-top {
  border-top: 1.5px solid var(--basic-grey-light);
}

.border-bottom {
  border-bottom: 1.5px solid var(--basic-grey-light);
}

@media screen and (max-width: 620px) {

}

/*** ------------ end.content ------------ ***/

/*** ------------ hello__block ------------ ***/

.hello__block {
  padding: 64px 64px 96px;
}

.hello-block__title {
  font-weight: 400;
  font-size: 56px;
  line-height: 64px;
  text-align: center;
}

/*** ------------ end.hello__block ------------ ***/

/*** ------------ search__block ------------ ***/

* + .search__block {
  margin-top: 48px;
}

.search__block form {
  padding: 16px;
  background: var(--basic-grey-light);
  border-radius: 16px;
}

.search-block__input {
  position: relative;
  width: 72%;
  padding-right: 32px;
}

.search-block__button {
  position: relative;
  width: 28%;
}

.search-block__input input,
.search-block__button button {
  font-weight: 100;
  font-size: 20px;
  line-height: 28px;
}

.search-block__tags {
}

* + .search-block__tags {
  margin-top: 16px;
}

.search-block-tags__title {
  position: relative;
}

.search-block-tags__list {
  position: relative;
}

* + .search-block-tags__list {
  margin-left: 16px;
}

.search-block-tags__list li {
  position: relative;
  display: inline-block;
  padding: 8px 16px;
  border-radius: 16px;
  background: var(--basic-grey-pale);
  cursor: pointer;
  transition: all .4s ease;
}

.search-block-tags__list li + li {
  margin-left: 8px;
}

.search-block-tags__list li:hover {
  color: var(--basic-white);
  background: var(--basic-orange);
  transition: all .4s ease;
}

/*** ------------ end.search__block ------------ ***/

/*** ------------ choose-region__block ------------ ***/

* + .choose-region__block {
  margin-top: 32px;
}

.choose-region__block .item h5 {
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
  margin-bottom: 24px;
}

.choose-region__block .item ul li {
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  transition: all .4s ease;
}

.choose-region__block .item ul li + li {
  margin-top: 12px;
}

.choose-region__block .item ul li:hover {
  color: var(--basic-green);
  transition: all .4s ease;
}

.choose-region__block .item ul li.active {
  color: var(--basic-orange);
}

/*** ------------ end.choose-region__block ------------ ***/

/*** ------------ service__list ------------ ***/

.service__list {
}

.service__list:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  display: block;
  width: calc(1024px + 96px);
  height: 100%;
  background: var(--basic-grey-pale);
  border-radius: 16px;
}

.service-list-wrap__item {
  position: relative;
  display: inline-block;
  height: 296px;
  overflow: hidden;
  border-radius: 16px;
  transition: all .4s ease;
}

.service-list-wrap__item img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  transition: all .4s ease;
}

.service-list-wrap__item:hover img {
  width: 110%;
  height: 110%;
  transition: all .4s ease;
}

.service-list-wrap__about {
  position: absolute;
  display: block;
  bottom: 16px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  width: calc(100% - 32px);
  padding: 16px;
  background: var(--basic-grey-light);
  border-radius: 16px;
  text-align: center;
  z-index: 34;
  transition: all .4s ease;
}

.service-list-wrap__item:hover .service-list-wrap__about {
  color: var(--basic-white);
  background: var(--basic-green);
  transition: all .4s ease;
}

/*** ------------ end.service__list ------------ ***/

/*** ------------ carousel ------------ ***/

.carousel,
.carousel-wrapper,
.carousel-items {
  position: relative;
  display: block;
  z-index: 66;
}

.carousel-wrapper {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.carousel-items {
  display: flex;
  width: 9000px;
  overflow-x: hidden;
}

.carousel .carousel-block {
  position: relative;
  display: inline-block;
  width: 224px;
  height: 296px;
  overflow: hidden;
  border-radius: 16px;
  transition: all .4s ease;
}

.service__list .carousel .carousel-block {
  background: var(--basic-white);
}

.carousel .carousel-block:hover {
  transition: all .4s ease;
}

.carousel .carousel-block + .carousel-block {
  margin-left: 32px;
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
  transition: all .4s ease;
  z-index: 120;
}

.carousel-button:hover {
  background-color: var(--basic-green);
  transition: all .4s ease;
}

.carousel-button-left {
  left: -24px;
  background: url('../../assets/images/icons/arrows/icon__arrow-left-white.svg') no-repeat center var(--basic-black);
  background-size: 40%;
}

.carousel-button-right {
  right: -24px;
  background: url('../../assets/images/icons/arrows/icon__arrow-right-white.svg') no-repeat center var(--basic-black);
  background-size: 40%;
}

/*** ------------ end.carousel ------------ ***/

/*** ------------ categories__list ------------ ***/

* + .categories__list {
  margin-top: 24px;
}

.categories__list + * {
  margin-top: 32px;
}

.categories__list a:hover {
  color: var(--basic-green);
}

.categories-list__main {
  padding: 16px 0;
  display: inline-block;
}

.categories-list__main span {
  position: relative;
  z-index: 12;
}

.categories-list__main:before {
  content: '';
  position: absolute;
  top: 50%;
  left: -16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: block;
  width: calc(100% + 32px);
  height: 100%;
  background: var(--basic-grey-pale);
  border-radius: 16px;
  z-index: 1;
  transition: all .4s ease;
}

.categories-list__main:hover:before {
  background: var(--basic-grey-light);
  transition: all .4s ease;
}

.categories-list__main .categories-list-main__masters {
  margin-left: 16px;
  color: var(--basic-grey);
}

.categories__list .categories-list__main + ul {
  margin-top: 16px;
}

.categories__list ul ul {
  margin-top: 8px;
  padding-left: 16px;
}

.categories__list ul li + li {
  margin-top: 8px;
}

/*** ------------ end.categories__list ------------ ***/

/*** ------------ categories__tile ------------ ***/

* + .categories__tile {
  margin-top: 24px;
}

.categories__tile + * {
  margin-top: 32px;
}

.categories__tile a:hover {
  color: var(--basic-green);
}

.categories__tile .wrap {
  //height: 100%;
  padding: 24px;
  background: var(--basic-grey-pale);
  border-radius: 16px;
}

.categories__tile .wrap:hover {
  background: var(--basic-grey-light);
  transform: translateY(-8px);
  -webkit-transform: translateY(-8px);
}

.categories-tile__icon {
  position: relative;
  width: 64px;
  height: 64px;
  border-radius: 16px;
  overflow: hidden;
  background: var(--basic-white);
}

.categories-tile__title {
  line-height: 20px;
}

* + .categories-tile__title {
  width: calc(100% - 80px);
  margin-left: 16px;
}

/*** ------------ end.categories__tile ------------ ***/

/*** ------------ result__list ------------ ***/

* + .result__list {
  margin-top: 24px;
}

.result-list__wrap li {
  padding: 24px;
  background: var(--basic-grey-pale);
  transition: all .4s ease;
}

.result-list__wrap li:hover {
  background: var(--basic-grey-light);
  transition: all .4s ease;
}

.result-list__wrap li:first-child {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.result-list__wrap li:last-child {
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
}

.result-list__wrap li + li {
  margin-top: 2px;
}

.result-company__logo {
  position: relative;
  display: block;
  width: 96px;
  height: 96px;
  background: var(--basic-white);
  border-radius: 24px;
  overflow: hidden;
}

* + .result-company__about {
  width: calc(100% - 120px);
  margin-left: 24px;
}

.result-company__title {
  font: 300 20px/24px 'euclid-square';
  transition: all .4s ease;
}

.result-list__wrap li:hover .result-company__title {
  color: var(--basic-orange);
  transition: all .4s ease;
}

* + .result-company__info {
  margin-top: 8px;
}

.result-company__info div[class^="result-company-info__"] {
  position: relative;
  display: block;
  width: 100%;
  padding-top: 8px;
  padding-left: 32px;
  margin-top: 8px;
}

.result-company__info div[class^="result-company-info__"] + div[class^="result-company-info__"] {
  border-top: 1.5px solid var(--basic-grey-border);
}

.result-company__info div[class^="result-company-info__"] p + p {
  margin-top: 0;
}

.result-company-phone__showButton {
  cursor: pointer;
}

.result-company-phone__showButton,
.result-company-phone__hideNum.__is-active {
  display: block;
}

.result-company-phone__hideNum,
.result-company-phone__showButton.__is-disabled {
  display: none;
}

.result-company__info .icon__before:before {
  top: 6px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background: var(--basic-white);
  border-radius: 50%;
}

.result-list__map {
  height: 563px;
  border-radius: 24px;
  overflow: hidden;
  position: sticky;
  top: 100px;
}

/*** ------------ end.result__list ------------ ***/

/*** ------------ how-it-works__block ------------ ***/

* + .how-it-works__block {
  margin-top: 32px;
}

.how-it-works__block .item > .wrap {
  height: 360px;
  background: var(--basic-white);
  border-radius: 16px;
}

.how-it-works__block .item > .wrap:before {
  position: absolute;
  top: 24px;
  left: 24px;
  font: 700 80px/80px 'euclid-square';
  color: var(--basic-green);
  pointer-events: none;
  opacity: .25;
  z-index: 1;
}

.how-it-works__block .item:nth-child(1) > .wrap:before {
  content: '01';
}

.how-it-works__block .item:nth-child(2) > .wrap:before {
  content: '02';
}

.how-it-works__block .item:nth-child(3) > .wrap:before {
  content: '03';
}

.how-it-works__text {
  margin-top: auto;
  padding: 24px;
}

/*** ------------ end.how-it-works__block ------------ ***/

/*** ------------ blog-news__block ------------ ***/

* + .blog-news__block {
  margin-top: 32px;
}

.blog-news__block .item a,
.blog-news__block .item .blog-news-block__img {
  overflow: hidden;
  border-radius: 16px;
}

.blog-news__block .item .blog-news-block__img {
  position: relative;
  display: block;
  width: 100%;
  height: 240px;
  background: var(--basic-grey-pale);
}

.blog-news__block .item .blog-news-block__img-full {
  height: 100%;
}

.blog-news__block .item .blog-news-block__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

.blog-news__block .item .blog-news-block__title {
  margin-top: 24px;
  transition: all .4s ease;
  z-index: 34;
}

.blog-news__block .item .blog-news-block__img-full + .blog-news-block__title {
  position: absolute;
  margin-top: 0;
  padding: 24px;
  bottom: 0;
  background: rgba(255, 255, 255, .72);
}

.blog-news__block .item .wrap:hover .blog-news-block__title {
  color: var(--basic-green);
  transition: all .4s ease;
}

.blog-news__block .item .wrap:hover .blog-news-block__img-full + .blog-news-block__title {
  background: var(--basic-grey-light);
}

/*** ------------ end.blog-news__block ------------ ***/

/*** ------------ enter__block ------------ ***/

.enter__block {
  height: 100%;
  min-height: 100vh;
}

.enter__block:before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 50%;
  height: 100%;
  background: url('../../assets/images/bg/bg__enter-block.jpg') no-repeat center var(--basic-green);
  background-size: cover;
  z-index: 12;
}

.enter__block .container {
  margin-top: auto;
  margin-bottom: auto;
}

.enter__form {
  position: relative;
  display: block;
  width: 432px;
  height: auto;
  margin-top: 44px;
  border-radius: 16px;
  background: var(--basic-grey-pale);
  overflow: hidden;
}

.enter-form__title {
  background: var(--basic-grey-light);
  border-bottom: 1.5px solid var(--basic-grey-border);
}

.enter-form__title p:nth-child(1) span {
  margin-left: auto;
}

.enter__form > * {
  padding: 32px;
}

.enter-form__inputs label {
  position: relative;
  display: inline-block;
  margin-bottom: -6px;
  color: var(--basic-grey);
  background: var(--basic-grey-border);
  border-radius: 12px;
  padding: 4px 16px;
}

.enter-form__inputs input {
  position: relative;
  display: block;
  width: 100%;
  padding: 16px;
  background: var(--basic-white);
  border: 1.5px solid var(--basic-grey-border);
}

.enter-form__inputs input + label {
  margin-top: 8px;
}

.enter-form__inputs input + button {
  margin-top: 16px;
}

.enter-form__choice a {
  padding: 16px 16px 16px 48px;
  background: var(--basic-grey-light);
  border: 1.5px solid var(--basic-grey-border);
  border-radius: 16px;
}

.enter-form__choice a:hover {
  background: var(--basic-white);
  border-color: var(--basic-green);
}

.enter-form__choice a:before {
  content: '';
  position: absolute;
  top: 20px;
  left: 16px;
  display: block;
  width: 16px;
  height: 16px;
  background: var(--basic-grey-border);
  border-radius: 50%;
  z-index: 12;
}

.enter-form__choice a,
.enter-form__choice a p + p {
  margin-top: 8px;
}

/*** ------------ end.enter__block ------------ ***/

/*** ------------ authorized__page ------------ ***/

.authorized__page {
}

/*** ------------ profile__menu ------------ ***/

.profile__menu > li:nth-child(1) {
  padding-left: 48px;
}

.profile__menu > li:nth-child(1) ul {
  left: 16px;
}

.profile__menu .profile-menu__avatar {
  position: absolute;
  top: 50%;
  left: -48px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  display: block;
  width: 32px;
  height: 32px;
  background: var(--basic-grey-light);
  border-radius: 50%;
  overflow: hidden;
}

.profile__menu .profile-menu__avatar img,
.authorized-profile-block-header__avatar img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

/*** ------------ authorized__menu ------------ ***/

.authorized__menu a {
  height: 112px;
  padding: 24px;
  background: var(--basic-grey-light);
  border-radius: 16px;
}

.authorized__menu a:hover {
  color: var(--basic-white);
  background: var(--basic-black);
}

.authorized__menu .create-new-order__button {
  color: var(--basic-white);
  background: var(--basic-orange);
}

.authorized__menu .create-new-order__button:hover {
  background: var(--basic-green);
}

.authorized__menu a > div {
  margin-top: auto;
}

.authorized__menu .create-new-order__button:before,
#orders-count {
  position: absolute;
  top: 16px;
  right: 16px;
  display: block;
  width: 48px;
  height: 48px;
  text-align: center;
  background: var(--basic-white);
  border-radius: 50%;
  z-index: 12;
  transition: all .4s ease;
}

.authorized__menu .create-new-order__button:before {
  content: '+';
  font: 100 48px/40px 'euclid-square';
  color: var(--basic-orange);
}

.authorized__menu .create-new-order__button:hover:before {
  color: var(--basic-green);
  transition: all .4s ease;
}

#orders-count {
  font: 400 16px/48px 'euclid-square';
  color: var(--basic-black);
}

/*** ------------ authorized-profile__block ------------ ***/

.authorized-profile__block {
  padding: 0 128px;
}

.authorized-profile__block > .wrap {
  background: var(--basic-grey-pale);
  border-radius: 16px;
  overflow: hidden;
}

.authorized-profile__block > .wrap > div {
  padding: 32px;
}

.authorized-profile-block__header {
  border-bottom: 1.5px solid var(--basic-white);
}

.authorized-profile-block__header:last-child {
  border-bottom: 0;
}

.authorized-profile-block-header__avatar {
  position: relative;
  display: block;
  width: 64px;
  height: 64px;
  margin-right: 32px;
  background: var(--basic-grey-border);
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.authorized-profile-block-header__name + .authorized-profile-block-header__account {
  margin-top: 4px;
}

.authorized-profile-block-header__account {
  font-family: 'euclid-square';
}

.authorized-profile-block__settings + .authorized-profile-block__settings {
  margin-top: 16px;
}

.authorized-profile-block-settings__title {
  width: 120px;
  margin-right: 32px;
}

.authorized-profile-block-settings__param p + p {
  margin-top: 4px;
}

.authorized-profile-block-settings__param p + p a {
  color: var(--basic-orange);
}

.authorized-profile-block__footer {
  border-top: 1.5px solid var(--basic-white);
}

/*** ------------ list-orders__block ------------ ***/

.list-orders__block {
  padding: 0 128px;
}

.list-orders__item {
  padding: 32px;
  background: var(--basic-grey-pale);
  border-radius: 16px;
}

* + .list-orders__item {
  margin-top: 16px;
}

.list-orders__item:hover {
  color: var(--basic-orange);
  background: var(--basic-grey-light);
}

.list-orders-item__date {
  margin-left: auto;
}

.list-orders-item__executor-name,
.list-orders-item__executor-status {
  margin-top: 16px;
}

.list-orders-item__executor-status {
  display: inline-block;
  width: auto;
  padding: 4px 16px;
  border-radius: 8px;
  color: var(--basic-white);
}

.list-orders-item__executor-status.executor-status__wait {
  background: var(--basic-orange);
}

.list-orders-item__executor-status.executor-status__ready {
  background: var(--basic-green);
}

.list-orders-item__executor-status.executor-status__cancel {
  background: var(--basic-grey);
}

.list-orders__block .link__big-button {
  width: 100%;
  text-align: center;
}

/*** ------------ end.authorized__page ------------ ***/

/*** ------------ create-order__block ------------ ***/

.create-order__block {
  padding: 0 128px;
}

.create-order__block > .wrap {
  background: var(--basic-grey-pale);
  border-radius: 16px;
  overflow: hidden;
}

.create-order__block > .wrap > div {
  padding: 32px;
}

.create-order-block__header {
  border-bottom: 1.5px solid var(--basic-white);
}

.create-order-block__header:last-child {
  border-bottom: 0;
}

.create-order-block__header:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  height: 100%;
  background: var(--basic-green);
  opacity: .16;
  z-index: 1;
}

.create-order-block__percent-16:before {
  width: 16%;
}

.create-order-block__percent-39:before {
  width: 39%;
}

.create-order-block__percent-67:before {
  width: 67%;
}

.create-order-block__percent-81:before {
  width: 81%;
}

.create-order-block__percent-100:before {
  width: 100%;
  opacity: 1;
}

.create-order-block__percent-100,
.create-order-block__percent-100 .create-order-block-header__account {
  color: var(--basic-white) !important;
}

.create-order-block-header__text {
  position: relative;
  z-index: 23;
}

.create-order-block-header__name + .create-order-block-header__account {
  margin-top: 4px;
}

.create-order-block-header__account {
  font-family: 'euclid-square';
}

.create-order-block__settings + .create-order-block__settings {
  margin-top: 16px;
}

.create-order-block-settings__title {
  width: 176px;
  margin-right: 32px;
}

.create-order-block-settings__param {
  width: 100%;
}

.create-order-block-settings__title + .create-order-block-settings__param {
  width: calc(100% - 208px);
}

.create-order-block-settings__param p + p {
  margin-top: 4px;
}

.create-order-block-settings__param p + p a {
  color: var(--basic-orange);
}

.create-order-block__footer {
  border-top: 1.5px solid var(--basic-white);
}

.create-order-block__footer a + a {
  color: var(--basic-white);
  background: var(--basic-orange);
  border-color: transparent;
  margin-left: auto;
}

.create-order__block input[type="text"] {
  width: 100%;
  background: var(--basic-white);
}

.create-order-block-settings__radio + .create-order-block-settings__radio,
.create-order-block-settings__checkbox + .create-order-block-settings__checkbox {
  margin-top: 12px;
}

.create-order-block-settings__radio label,
.create-order-block-settings__checkbox label {
  position: relative;
  margin-left: 8px;
  cursor: pointer;
}

/*** ------------ end.create-order__block ------------ ***/

/*** ------------ bg ------------ ***/

.bg-grey-light {
  background: var(--basic-grey-light);
}

.bg-grey-pale {
  background: var(--basic-grey-pale);
}

.bg-green {
  background: var(--basic-green);
}

/*** ------------ end.bg ------------ ***/

/*** ------------ footer ------------ ***/

footer {
  position: relative;
  display: block;
  width: 100%;
  z-index: 345;
}

footer > .wrap:nth-child(1) {
  padding: 48px 0;
  background: var(--basic-black);
}

footer > .wrap:nth-child(2) {
  padding: 32px 0;
  background: var(--basic-dark);
}

/*** ------------ footer__menu ------------ ***/

.footer__menu {
  margin-top: 16px;
}

.footer__info .footer__menu {
  margin-top: 0;
}

.footer__menu li > ul,
.footer__menu li > ul li + li {
  margin-top: 8px;
}

.footer__menu > li + li {
  margin-left: 16px;
}

.footer__info .footer__menu > li + li {
  margin-left: auto;
}

.footer__menu li a {
  color: var(--basic-grey-footer);
  border-bottom: 1.5px solid var(--basic-grey-footer);
}

.footer__info .footer__menu > li > a {
  color: var(--basic-white);
  border-bottom: 1.5px solid var(--basic-white);
}

.footer__menu li a:hover,
.footer__menu li.active a {
  color: var(--basic-white);
  border-color: var(--basic-white);
}

/*** ------------ footer__info ------------ ***/

* + .footer__info {
  margin-top: 16px;
}

.footer__info p {
  color: var(--basic-grey-footer);
}

/*** ------------ end.footer ------------ ***/

/*** ------------ popup ------------ ***/

.popup {
  position: fixed;
  display: none;
  z-index: 998;
}

.popup__city {
  width: 1024px;
  max-height: calc(100vh - 64px);
  padding: 32px;
  background: var(--basic-white);
  border-radius: 16px;
}

.popup__city .wrap__scroll {
  max-height: calc(100vh - 212px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.popup__city * + .wrap__scroll {
  margin-top: 48px;
}

.popup__city .wrap__scroll::-webkit-scrollbar-track {
  background: var(--basic-grey-pale);
}

.popup__city .wrap__scroll::-webkit-scrollbar-thumb {
  background: var(--basic-grey);
}

#overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  background: var(--basic-black);
  opacity: .64;
  z-index: 666;
}

/*** ------------ end.popup ------------ ***/

/*** ------------ close__button ------------ ***/

.close__button {
  position: absolute;
  top: 0;
  right: 0;
  width: 64px;
  height: 64px;
  padding: 0;
  font: 300 0/0 'fira-sans';
  border: none;
  background: transparent;
  transition: all .4s ease;
  z-index: 100;
}

.popup .close__button {
  right: -64px;
}

.close__button:before,
.close__button:after {
  content: '';
  position: absolute;
  width: 1.5px;
  height: 24px;
  top: 32%;
  left: 50%;
  background: var(--basic-white);
}

.close__button:before,
.close__button:before {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: all .4s ease;
}

.close__button:after,
.close__button:after {
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: all .4s ease;
}

.close__button:hover:before,
.close__button:hover:before {
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);
  transition: all .4s ease;
}

.close__button:hover:after,
.close__button:hover:after {
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  transition: all .4s ease;
}

/*** ------------ end.close__button ------------ ***/


a.submit {
  font-weight: 100;
  font-size: 20px;
  line-height: 28px;
  color: var(--basic-white);
  background: var(--basic-green);
  transition: all .4s ease;
  position: relative;
  display: inline-block;
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  border: 0;
  font-family: 'euclid-square';
  text-align: center;
}

/*** ------------ why-we__block ------------ ***/

* + .why-we__block {
  margin-top: 24px;
}

.why-we__block + .why-we__block {
  margin-top: 32px;
}

.why-we__block .item__img .wrap {
  width: calc(100% - 48px);
  height: calc(100% - 96px);
  overflow: hidden;
  background: var(--basic-grey-light);
  border-radius: 24px;
}

.why-we__block .item__img:nth-child(1) .wrap {
  margin: 48px 48px 48px 0;
}

.why-we__block .item__img:nth-child(2) .wrap {
  margin: 48px 0 48px 48px;
}

.why-we__block .item__img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

/*** ------------ why-we__block ------------ ***/


.verified {
  position: absolute;
  background: url('../../assets/images/icons/verified.png') 50% 50% no-repeat;
  right: 10px;
  top: 10px;
  height: 24px;
  width: 24px;
}

.closeButton {
  position: absolute;
  background: url('../../assets/images/icons/close.png') 50% 50% no-repeat #ffffff;
  right: 0;
  top: 0;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.send-variant {
  display: flex;
  align-content: space-between;
  margin: 20px;

  .send-free {
    margin-right: 20px;

    span {
      font-weight: 100;
      font-size: 20px;
      line-height: 28px;
      color: var(--basic-white);
      background: var(--basic-grey-light);
      transition: all .4s ease;
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 8px;
      border-radius: 8px;
      border: 0;
      font-family: 'euclid-square';
      text-align: center;
    }
  }

  .send-warranty {
    span {
      font-weight: 100;
      font-size: 20px;
      line-height: 28px;
      color: var(--basic-white);
      background: var(--basic-green);
      transition: all .4s ease;
      position: relative;
      display: inline-block;
      width: 100%;
      padding: 8px;
      border-radius: 8px;
      border: 0;
      font-family: 'euclid-square';
      text-align: center;
    }
  }
}

.send-contact {

}

.modalName {
  font: 400 20px/24px 'euclid-square';
}

.mapSwitcher {
  display: none;
}

.addresses {
  &-item {
    border-top: 1px solid #dddddd;
    padding: 0 0 10px 0;
    margin: 5px 0 0 0;

    &.active {
      background: #fffaeb;
    }
  }
}

.master-selector {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;

  &-button {
    display: inline-block;
    padding: 32px;
    border-radius: 16px;
    border: 1.5px solid var(--basic-grey-border);
    margin: 20px;
    text-align: center;
    min-width: 310px;
    transition: all .4s ease;
    cursor: pointer;
    font-size: 30px;
    color: var(--basic-green);

    &:before {
      font-size: 60px;
      line-height: 60px;
      color: var(--basic-orange);
      position: relative;
      display: block;
      margin-bottom: 20px;
    }

    &:hover {
      color: var(--basic-white);
      background: var(--basic-green);

      &:before {
        color: var(--basic-white);
      }
    }
  }
}

.step-master {
  position: relative;

  @media all and (max-width: 620px) {
    flex-direction: column;
    justify-content: center;
  }

  .closer {
    font-size: 32px;
    line-height: 32px;
    height: 32px;
    width: 32px;
    color: #ff0000;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;

    &:before {
      font-size: 32px;
      line-height: 32px;
    }
  }

  .step-master-step {
    margin-right: 20px;
    font-size: 16px;
    font-weight: bold;
    color: var(--basic-orange);

    @media all and (max-width: 620px) {
      margin: 5px 0;
    }


    span {
      color: var(--basic-green);
      font-weight: bold;
      font-size: 20px;
      border-bottom: 2px solid #ff0000;
      margin-right: 10px;
      min-width: 19px;
      display: inline-block;
    }
  }
}

.step-master-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .text {
    width: 100%;
    max-width: 600px;
    margin: 10px 0;

    textarea {
      border: 1px solid var(--basic-grey-border);
      width: 100%;
    }
  }

  .input {
    width: 100%;
    max-width: 600px;
    margin: 10px 0;

    input {
      border: 1px solid var(--basic-grey-border);
      width: 100%;
    }
  }


  button {
    font-weight: 100;
    font-size: 20px;
    line-height: 28px;
    color: var(--basic-white);
    background: var(--basic-green);
    transition: all .4s ease;
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    border: 0;
    font-family: 'euclid-square';
    text-align: center;
  }

}


.section-box {
  border-radius: 3px;
  background-color: #fff;
}

.basic-section--appearance-horizontal {
  padding: 0 16px;
}

@media all and (min-width: 1024px) {
  .basic-section--appearance-horizontal {
    padding: 0 24px;
  }
}

.vacancy-card__inner {
  padding: 24px 0 21px;
  position: relative;
  min-height: 111px;
}


.vacancy-card__date {
  float: right;
  margin-left: 10px;
  color: #999;
}

.vacancy-card__info {
  margin-left: 65px;
  max-width: 513px;
}

.vacancy-card__company {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 4px;
}

.vacancy-card__title {
  overflow: hidden;
  word-break: break-word;
  font-weight: 700;
  font-size: 21px;
}

.vacancy-card__meta, .vacancy-card__salary {
  margin-top: 4px;
}

.inline-separator {
  white-space: pre-wrap;
  color: #999;
}

.vacancy-card__meta, .vacancy-card__salary {
  margin-top: 4px;
}

.vacancy-card__skills {
  margin-top: 12px;
}

.basic-salary {
  font-weight: 700;
  color: #68c07b;
}

.content-section {
  margin-top: 16px;
}

.content-section__header {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
  margin-bottom: 4px;
}

.content-section__title {
  flex: 1 1;
  min-width: -webkit-min-content;
  min-width: min-content;
  margin: 0;
  font: inherit;
  font-weight: 400;
  color: #999;
}

.page-title__title {
  margin: 0;
  word-break: break-word;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.1428571429;
}

.vacancy-header__title {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  grid-gap: 16px;
  gap: 16px;
}

.page-section {
  margin: 0 0 14px;
}


.basic-section {
  padding: 16px;
}

@media all and (min-width: 1024px) {
  .basic-section {
    padding: 16px 24px 28px;

  }
}

.section-title--divider {
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid #ededed;
}

.section-title {
  display: flex;
  align-items: center;
  font-size: 18px;
  line-height: 1.27777;
}
.section-title__title {
  flex: 1 1;
}

.vacancy-description__text h3 {
  margin: 0 0 8px;
  font-size: 18px;
  line-height: 24px;
}

.vacancy-description__text p {
  margin-bottom: 15px;
}